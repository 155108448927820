@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "GolosText Regular";
    src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "GolosText Medium";
    src: url("./assets/fonts//GolosText-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "GolosText Semibold";
    src: url("./assets/fonts/GolosText-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "GolosText Bold";
    src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}

*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

#hide_scrollbar::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for WebKit browsers */
}

#hide_scrollbar {
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar for Internet Explorer and Edge */
}


/* global */
ul {
    list-style: initial;
}

/* Remove the outerline of the text editor */
*:focus {
    outline: none;
}



/* ----- text editor ----- */

#editorjs {
    padding: 10px;
    border-radius: 4px;
}

.ce-header {
    font-weight: 600;
    font-size: 35px;
    color: #4b4b4b;
}

.ce-paragraph {
    color: #5b5b5b;
    font-size: 16px;
    line-height: 1.5;
}

.cdx-marker {
    background: #ebf8ff !important;
    color: #2c5282 !important;
    padding: 1px 4px 2px 4px;
}

.cdx-quote__text {
    border-left: 4px solid #ccc;
    padding-left: 10px;
    font-style: italic;
    background-color: #d3d3d32f;
    color: #4a4949;
}

.ce-code {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
}

.ce-delimiter {
    text-align: center;
    margin: 20px 0;
    font-size: 24px;
}

.ce-table {
    width: 100%;
    border-collapse: collapse;
}

.ce-table td,
.ce-table th {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: left;
}

.ce-raw {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    font-family: monospace;
}

.ce-warning {
    border-left: 4px solid #f39c12;
    padding-left: 10px;
    margin: 10px 0;
}

.ce-checklist__item {
    display: flex;
    align-items: center;
}

.cdx-input {
    color: #5b5b5b;
    font-weight: 400;
    font-size: 16px;
}

/* Inline image */
#image-url{
    overflow-x: scroll;
}


/* quill-editor */
.ql-toolbar.ql-snow {
    border: 0 !important;
}

.ql-container.ql-snow {
    border: 0 !important;
}

.ql-editor {
    color: #3F4D5A;
    font-size: 12px;
}

.ql-editor img {
    width: 100%;
    max-width: 400px;
}