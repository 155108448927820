#comment{
    color: #3F4D5A;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding-left: 30px;
    gap: 5px;
    font-family: Arial, Helvetica, sans-serif;

    img{
        width: 100%;
        max-width: 400px;
    }
    p{
        font-weight: 400;
    }

    ul {
        list-style: initial;
    }

    li {
        margin-left: 15px;
    }

    pre{
        background-color: #23241f;
        color: #f8f8f2;
        font-size: 12px;
        white-space: pre-wrap;
        border-radius: 5px;
        padding: 7px 10px;
    }
}