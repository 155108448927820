// top
.top {
    position: relative;
    display: inline-block;

    .tooltiptext {
        visibility: hidden;
        min-width: 120px;
        background-color: #d9a45eb6;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        /* Position the tooltip above */
        left: 50%;
        margin-left: -60px;
        /* Center the tooltip horizontally */
        opacity: 0;
        transition: opacity 0.3s;
        animation: none;
        font-size: 14px;
    }

    &:hover .tooltiptext {
        visibility: visible;
        animation: fadeIn 0.3s 1s forwards;
        /* Delay for 2 seconds */
    }

    .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        /* Position the arrow at the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #d9a45eb6 transparent transparent transparent;
        /* Arrow pointing down */
    }

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
}

// right
.right {
    position: relative;
    display: inline-block;

    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 125%;
        /* Position the tooltip to the right */
        margin-top: -16px;
        /* Center the tooltip vertically */
        opacity: 0;
        transition: opacity 0.3s;
        animation: none;
    }

    &:hover .tooltiptext {
        visibility: visible;
        animation: fadeIn 0.3s 1s forwards;
        /* Delay for 2 seconds */

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            /* Position the arrow on the left side of the tooltip */
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #555 transparent transparent;
            /* Arrow pointing left */
        }
    }

    @keyframes fadeIn {
        to {
            opacity: 1;
        }

    }
}

// left
.left {
    position: relative;
    display: inline-block;

    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 125%;
        /* Position the tooltip to the left */
        margin-top: -16px;
        /* Center the tooltip vertically */
        opacity: 0;
        transition: opacity 0.3s;
        animation: none;
    }

    &:hover .tooltiptext {
        visibility: visible;
        animation: fadeIn 0.3s 1s forwards;
        /* Delay for 2 seconds */

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            /* Position the arrow on the right side of the tooltip */
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent #555;
            /* Arrow pointing right */
        }
    }

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
}

// bottom
.bottom {
    position: relative;
    display: inline-block;

    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: 125%;
        /* Position the tooltip below */
        left: 50%;
        margin-left: -60px;
        /* Center the tooltip horizontally */
        opacity: 0;
        transition: opacity 0.3s;
        animation: none;
    }

    &:hover .tooltiptext {
        visibility: visible;
        animation: fadeIn 0.3s 1s forwards;
        /* Delay for 2 seconds */
    }

    .tooltiptext::after {
        content: "";
        position: absolute;
        bottom: 100%;
        /* Position the arrow at the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #555 transparent;
        /* Arrow pointing up */
    }

}