.loader-deafult {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.loader-deafult::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 1.5px solid #ffffff5d;
    animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
}


// loading-modal
.loading-modal {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #FFF;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;


    @keyframes animloader {
        0% {
            box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
        }

        33% {
            box-shadow: -38px 0px, -14px -12px, 14px 0, 38px 0;
        }

        66% {
            box-shadow: -38px 0px, -14px 0, 14px -12px, 38px 0;
        }

        100% {
            box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
        }
    }
}

// chat

.loading-chat {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #FFF;
    left: -100px;
    box-sizing: border-box;
    animation: chatShadowRolling 2s linear infinite;
}

@keyframes chatShadowRolling {
    0% {
        box-shadow: 0px 0 rgba(52, 37, 255, 0.498), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    12% {
        box-shadow: 100px 0 rgba(52, 37, 255, 0.498), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    25% {
        box-shadow: 110px 0 rgba(10, 0, 151, 0.377), 100px 0 rgba(10, 0, 151, 0.377), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    36% {
        box-shadow: 120px 0 rgba(10, 0, 151, 0.377), 110px 0 rgba(10, 0, 151, 0.377), 100px 0 rgba(10, 0, 151, 0.377), 0px 0 rgba(255, 255, 255, 0);
    }

    50% {
        box-shadow: 130px 0 rgba(10, 0, 151, 0.377), 120px 0 rgba(10, 0, 151, 0.377), 110px 0 rgba(10, 0, 151, 0.377), 100px 0 rgba(10, 0, 151, 0.377);
    }

    62% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 rgba(10, 0, 151, 0.377), 120px 0 rgba(10, 0, 151, 0.377), 110px 0 rgba(10, 0, 151, 0.377);
    }

    75% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 rgba(10, 0, 151, 0.377), 120px 0 rgba(10, 0, 151, 0.377);
    }

    87% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 rgba(10, 0, 151, 0.377);
    }

    100% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
    }
}


.loading-feed {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #FFF;
    left: -100px;
    box-sizing: border-box;
    animation: chatShadowRollingfeed 2s linear infinite;
}


@keyframes chatShadowRollingfeed {
    0% {
        box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    12% {
        box-shadow: 100px 0 #747474, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    25% {
        box-shadow: 110px 0 #747474, 100px 0 #747474, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    36% {
        box-shadow: 120px 0 #747474, 110px 0 #747474, 100px 0 #747474, 0px 0 rgba(255, 255, 255, 0);
    }

    50% {
        box-shadow: 130px 0 #747474, 120px 0 #747474, 110px 0 #747474, 100px 0 #747474;
    }

    62% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 #747474, 120px 0 #747474, 110px 0 #747474;
    }

    75% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 #747474, 120px 0 #747474;
    }

    87% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 #747474;
    }

    100% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
    }
}